
export default {
    data() {
        return {
            skipToMain: false,
            sideNavOpen: true,
            topNavHeight: 0
        };
    },
    computed: {
        storageUrl() {
            return process.env.STORAGE_URL;
        },
        user() {
            return this.$store.state.user;
        },
        login() {
            return process.env.LOGIN_URL;
        }
    },
    methods: {
        refresh() {
            window.location.reload(true);
        }
    },
    async mounted() {
        const browser = this.$ua.browser();
        const browserVersion = this.$ua.browserVersion();
        if (!["Chrome", "Firefox", "Safari"].includes(browser)) {
            if (browser === "Edge" && Number(browserVersion.split(".")[0]) >= 79) {
                // do nothing
            } else {
                this.$refs.browserWarning.show();
            }
        }

        const user = this.$store.state.user;
        this.$i18n.setLocale(user.language);
        window.history.replaceState("", "", this.switchLocalePath(user.language));

        window.addEventListener("beforeunload", event => {
            const gaUid = this.$store.state.user.id || false;
            if (gaUid) {
                window.ga("set", "dimension1", gaUid);
            }
            window.ga("set", "page", "/sessionend");
            window.ga("set", "dimesion2", JSON.stringify(new Date().getTime() / 1000));
            window.ga("send", "pageview");
            delete event.returnValue;
        });

        const now = Date.now();
        if (now > this.user.exp * 1000) {
            this.$bvModal.show("session_timeout");
        }
        document.addEventListener("copy", () => {
            this.$saveLocal("copiedValue", document.getSelection().toString());
        });
        this.$nuxt.$on("toggleSideNav", () => {
            this.sideNavOpen = !this.sideNavOpen;
        });
        this.topNavHeight = this.$getTopNavHeight();
    },
    async middleware({ store, redirect, route, $axios }) {
        if (store.state.user.onlyCode && !route.name.startsWith("code")) {
            return redirect("/code");
        }
        if (store.state.user.sections && store.state.user.account === "student") {
            if (!store.state.user.section && store.state.user.sections.length === 1) {
                await $axios
                    .$get("/section")
                    .then(data => {
                        const user = { ...store.state.user };
                        user.section = data;
                        store.commit("user", user);
                    })
                    .catch(err => {
                        console.error(err);
                    });
                return redirect(route.fullPath);
            } else if (!store.state.user.section) {
                return redirect(`${process.env.SS_URL}/account/sections?platform=v2&next=${route.path}`);
            }
        }
    }
};


export default {
    data() {
        return {
            status: ""
        };
    },
    computed: {
        live() {
            return process.env.STAGE === "prod";
        },
        user() {
            return this.$store.state.user;
        },
        local() {
            return Boolean(this.user.onlyCode);
        },
        currentLesson() {
            return this.$store.state.currentLesson || {};
        },
        sectionsUrl() {
            return `${process.env.SS_URL}/account/sections?platform=v2&next=${this.$route.path}`;
        },
        onCodePage() {
            return this.$route.name && this.$route.name.startsWith("code") && this.$store.state.ui.topRightNav;
        }
    },
    methods: {
        openNav() {
            this.$nuxt.$emit("toggleSideNav");
        },
        closeNav() {
            setTimeout(() => {
                this.status = "";
                document.getElementById("root").classList.remove("open");
            }, 150);
        },
        showHelp() {
            this.$refs.help.$refs.modal.show();
        },
        showWelcomeVideo() {
            this.$refs.welcome.$refs.modal.show();
        },
        showColorPicker() {
            this.$root.$emit("bv::enable::popover", "colorpicker");
            this.$root.$emit("bv::hide::tooltip", "nav-colorpicker");
        },
        showImageBank() {
            if (!this.$store.state.images.length) {
                // Load images from file only if store is empty.
                this.$store.commit("getImages", require("~/static/images.json"));
            }
            this.$refs.imagebank.$refs.modal.show();
        }
    },
    mounted() {
        const app = this;
        this.$nuxt.$on("closeNav", this.closeNav);
    }
};
